import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {Button, MenuItem, TextField, Checkbox, FormControlLabel} from "@material-ui/core";
import {useEffect, useState} from "react";
import coreService from "../../services/coreService";
import useToken from "../../hooks/useToken";
import {SupportMap} from "./supportHelper";

const useStyles = makeStyles((theme) => ({
    infoTitle: {
        marginTop: '20px',
        marginBottom: '8px',
        fontSize: '18px',
        fontWeight: 'bold',
    },
    checkbox: {
        marginTop: '20px',
        marginBottom: '20px',
        display: 'block',
    }
}));

export default function FeedbackForm(props) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const {token} = useToken();
    const data = props.data;
    const [reply, setReply] = useState({value: '', errorMsg: ''});
    const [support, setSupport] = useState({value: '', errorMsg: ''});
    const [isCC, setIsCC] = useState(false);

    useEffect(()=> {
        setReply({
            value: '',
            errorMsg: ''
        });
        setSupport({
            value: '',
            errorMsg: ''
        });
        // setIsCC(false);
    }, [data]);

    const getReplyText = ()=> {
        return data.status === 'new'? 'Reply': 'Comment';
    }

    const onReplyInput = (value)=> {
        setReply({
            value: value,
            errorMsg: ''
        });
    }

    const onSupportChange = (value)=> {
        setSupport({
            value: value,
            errorMsg: ''
        });
    }

    const onSend = ()=> {
        let valid = true;
        if(!reply.value || !reply.value.trim()) {
            setReply({
                value: reply.value,
                errorMsg: 'Can not be empty!'
            });
        }
        if(!support.value || !support.value.trim()) {
            setSupport({
                value: support.value,
                errorMsg: 'Can not be empty!'
            });
            valid = false;
        }

        if(valid) {
            coreService.replyFeedback(token, data.feedbackId, reply.value, support.value, isCC).then((data)=> {
                props.onReplySuccess();
            }).catch((error)=> {

            })
        }
    }

    return (
        <div>
            <Typography className={classes.infoTitle}>{getReplyText()}</Typography>
            <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                multiline={true}
                minRows={5}
                value={reply.value}
                error={reply.errorMsg? true: false}
                helperText={reply.errorMsg}
                onChange={e=>onReplyInput(e.target.value)}
            />

            <Typography className={classes.infoTitle}>Support Type</Typography>
            <TextField
                id="support"
                variant="outlined"
                value={support.value}
                fullWidth
                onChange={(event) => {
                    onSupportChange(event.target.value);
                }}
                error={support.errorMsg? true: false}
                helperText={support.errorMsg}
                select
            >{
                SupportMap.map((item, index)=> {
                    return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>;
                })
            }
            </TextField>

            <FormControlLabel control={<Checkbox checked={isCC} onChange={()=> setIsCC(!isCC)} color="primary" />}
                              label="CC to revenue" className={classes.checkbox} />

            <Button variant={'contained'} color="primary" onClick={onSend}>Send</Button>
        </div>
    )
}