import {useTranslation} from "react-i18next";
import Header from "./Header";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import NavigatorButton from "./NavigatorButton";
import iconFeedback from "../assets/img/iconFeedback.svg";
import iconAccount from "../assets/img/iconAccount.svg";
import iconSubscription from "../assets/img/iconSubscription.svg";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
    },
    catalogWrapper: {
        width: '100px',
        height: '100%',
        overflowY: 'auto',
        float: 'left',
        border: '1px solid #e3e3e3',
    },
    catalogButton: {
        width: '100%',
        height: '92px',
        textAlign: 'center'
    },
    pageWrapper: {
        width: 'calc(100% - 100px)',
        height: '100%',
        marginLeft: '101px',
        border: '1px solid #e3e3e3',
    },
    pageContent: {
        overflowY: 'auto'
    }
}));

export default function CommonLayout(props) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const [current, setCurrent] = useState(0);

    const catalogConfig = [
        {
            path: 'feedback',
            icon: iconFeedback,
            text: 'Feedback',
            selected: false,
        },
        {
            path: 'account',
            icon: iconAccount,
            text: 'Account',
            selected: false,
        },
        {
            path: 'subscription',
            icon: iconSubscription,
            text: 'Subscription',
            selected: false,
        },
    ];

    // useEffect(()=> {
    //     const path = window.location.href;
    //     catalogConfig.forEach((item) => {
    //         item.selected = path.indexOf(item.path) > 0;
    //     })
    // }, [])

    // const setUpCatalog = ()=> {
    //
    // }

    const onPageChange = ()=> {

    }

    return (
        <div className={classes.wrapper}>
            <Header />
            <div style={{flexGrow: 1}}>
                <div className={classes.catalogWrapper}>
                    {
                        catalogConfig.map((item, index) => {
                            return <NavigatorButton
                                key={item.text}
                                icon={item.icon}
                                text={item.text}
                                selected={index===current}
                                onClick={onPageChange}>
                            </NavigatorButton>
                        })
                    }
                </div>
                <div className={classes.pageWrapper}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}