import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import iconAndroid from "../../assets/img/android.png";
import iconIphone from "../../assets/img/iphone.png";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import FeedbackForm from "./FeedbackForm";
import {getLabelByValue as getSupportLabel} from "./supportHelper";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    detailContainer: {
        padding: '4px 24px 18px 25px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px 7px #f1f5fb',
        border: 'solid 1px #ccd0d6',
        backgroundColor: '#fff',
        marginLeft: '20px',
    },
    boxWrapper: {
        padding: '9px 10px 8px 15px',
        borderRadius: '5px',
        backgroundColor: '#f1f5fb',
        '& img': {
            height: '25px',
            width: '25px',
            float: 'left',
            objectFit: 'contain',
        },
        '& div': {
            fontSize: '20px',
            marginLeft: '35px',
        }
    },
    infoTitle: {
        marginTop: '20px',
        marginBottom: '8px',
        fontSize: '18px',
        fontWeight: 'bold',
    },
    email: {
    },
    timeStamp: {
        color: '#838383',
        marginTop: '5px',
        fontSize: '14px'
    },
    isCC: {
        marginTop: '10px',
    }
}));

export default function FeedbackDetail(props) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const data = props.data;

    const getIcon = () => {
        if(data.isAndroid === true) {
            return iconAndroid
        }
        return iconIphone;
    }

    const isAnonymous = () => {
        return data.status === 'anonymous';
    }

    const isReplied = () => {
        if(data.replyContent && data.replyContent.trim()) {
            return true;
        }
        return false;
    }

    const repliedDisplayPart = ()=> {
        const getReplyTimeStamp = ()=> {
            return moment.utc(data.replyTime).local().format("YYYY/MM/DD HH:mm");
        }
        return (
            <div>
                <Typography className={classes.infoTitle}>Reply</Typography>
                <div className={classes.boxWrapper}>
                    <Typography variant={'body1'}>{data.replyContent}</Typography>
                    <Typography variant={'body1'} className={classes.timeStamp}>{getReplyTimeStamp()}</Typography>
                </div>

                <Typography className={classes.infoTitle}>Reply User</Typography>
                <div className={classes.boxWrapper}>
                    <Typography variant={'body1'}>{data.replyUser}</Typography>
                </div>

                <Typography className={classes.infoTitle}>Support Type</Typography>
                <div className={classes.boxWrapper}>
                    <Typography variant={'body1'}>{getSupportLabel(data.category)}</Typography>
                </div>

                <FormControlLabel control={<Checkbox checked={data.isCC} disabled />}
                                  label="Already CC to revenue" className={classes.isCC}/>
            </div>
        );
    }

    const unRepliedDisplayPart = ()=> {
        return (
            <FeedbackForm data={data} onReplySuccess={props.onReplySuccess}/>
        );
    }

    return (
        <div className={classes.detailContainer}>
            <div>
                <Typography className={classes.infoTitle}>From</Typography>
                <div className={classes.boxWrapper}>
                    <img src={getIcon()}/>
                    <div>
                        <Typography className={classes.email}>{isAnonymous()? 'Anonymous' : data.userEmail}</Typography>
                        {!isAnonymous() && <Typography className={classes.timeStamp}>{'Version: ' + data.appVersion}</Typography>}
                    </div>
                </div>
            </div>

            <Typography className={classes.infoTitle}>Feedback</Typography>
            <div className={classes.boxWrapper}>
                <Typography variant={'body1'}>{data.content}</Typography>
                <Typography variant={'body1'} className={classes.timeStamp}>{data.timeStamp}</Typography>
            </div>

            {isReplied() && repliedDisplayPart()}
            {!isReplied() && unRepliedDisplayPart()}
        </div>
    )
}