import './App.css';
import AppRouters from "./AppRouters";
import {BrowserRouter as Router} from "react-router-dom";
import useToken from "./hooks/useToken";
import {redirectToLogin} from "./services/loginService";
import IAMCallback from "./components/IAMCallback";

function App() {
  const {token} = useToken();

  const path = window.location.pathname;
  const baseURL = process.env.REACT_APP_BASE_URL;
  if (path === baseURL + '/iamCallback') {
    return (
        <IAMCallback/>
    )
  }

  if (!token) {
    console.log('RedirectTo iam url');

    window.localStorage.setItem('access_url', window.location.href);
    redirectToLogin();
    return (<div></div>);
  } else {
    return (
        <Router basename={process.env.REACT_APP_BASE_URL}>
          <AppRouters></AppRouters>
        </Router>
    );
  }

}

export default App;
