import {useTranslation} from "react-i18next";
import Toolbar from "@material-ui/core/Toolbar";
import OpenDaisLogo from "./OpenDaisLogo";
import {Button} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {redirectToLogin, logout} from "../services/loginService";
import {useHistory} from "react-router-dom";
import useToken from '../hooks/useToken';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: 'white',
        height: '58px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    iconButton: {
        color: '#FFFFFF'
    },
    signBtn: {
        borderRadius: '28px',
        // [theme.breakpoints.up('md')]: {
        //     width: '100px',
        // },
    },
    signBtnTxt: {
        fontSize: '16px',
        fontFamily: 'RobotoRegular',
        letterSpacing: '0.08px',
        fontWeight: '600',
    }
}));

export default function Header(props) {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation();
    const {token, setToken} = useToken();

    const jump2Home = () => {
        const homeURL = '/';
        history.push(homeURL);
    }

    const signOutHandler = () => {
        if(token) {
            logout(token).then((_) => {
                setToken('');
                window.location.href = '/';
            });
        }
    }

    return (
        <Toolbar className={classes.header}>
            <OpenDaisLogo logoClick={jump2Home}></OpenDaisLogo>

            <Box sx={{ flexGrow: 1 }} />

            <Button variant={'contained'} color="primary" className={classes.signBtn} onClick={signOutHandler}>
                <span className={classes.signBtnTxt}>Sign out</span>
            </Button>
        </Toolbar>
    );
}
