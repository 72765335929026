import {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import {Typography} from "@material-ui/core";
import Header from "../components/Header";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        width: '100%',
        padding: '15% 20%',
        textAlign: 'center',
        boxSizing: 'border-box',
    }
}));

export default function UnAuthorized() {
    const classes = useStyles();

    return (
        <Grid container direction={'column'}>
            <Header />

            <div className={classes.contentWrapper}>
                <Typography variant={'h4'}>You have no authority to access the manage functions,
                    please sign out and login with admin account.</Typography>
            </div>
        </Grid>
    )
}