import axios from "axios";

const loginURL = process.env.REACT_APP_IAM_URL;

const redirectToLogin = function () {
    let iamUrl = loginURL;
    if(process.env.REACT_APP_IAM_CLIENT_ID && process.env.REACT_APP_IAM_REDIRECT_URI && process.env.REACT_APP_IAM_RESPONSE_TYPE){
        iamUrl += '?client_id=';
        iamUrl += process.env.REACT_APP_IAM_CLIENT_ID;
        iamUrl += '&redirect_uri=';
        iamUrl += process.env.REACT_APP_IAM_REDIRECT_URI;
        iamUrl += '&response_type=';
        iamUrl += process.env.REACT_APP_IAM_RESPONSE_TYPE;
    }
    console.info('redirect to iam service url=' + iamUrl);
    window.location.href = iamUrl;
}


const logout = function (token) {
    const url = process.env.REACT_APP_IAM_SERVICE_URL + '/logout';

    return new Promise((resolve, reject) => {
        axios.request({
            url: url,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                'accept': '*/*'
            },
            params: {
                'token': token
            }
        }).then(function (result) {
            if (result && (result.status === 200 || result.status === 201)) {
                resolve(result)
            } else {
                throw result;
            }
        }).catch(function (e) {
            reject(e);
        });
    })

}

export {
    redirectToLogin,
    logout,
}