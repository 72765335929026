import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import iconAndroid from "../../assets/img/android.png";
import iconIphone from "../../assets/img/iphone.png";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    itemWrapper: {
        padding: '12px',
        marginTop: '15px',
        borderRadius: '5px',
        border: 'solid 1px #bcc1c9',
        '&:hover': {
            border: 'solid 1px #114281',
            backgroundColor: '#f1f5fb',
        },
        '& img': {
            height: '25px',
            width: '25px',
            float: 'left',
            objectFit: 'contain',
        },
        '& div': {
            marginLeft: '35px'
        }
    },
    content: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    timeStamp: {
        color: '#838383',
        marginTop: '5px',
        fontSize: '14px'
    }
}));

export default function FeedbackItem(props) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const data = props.data;

    const getIcon = () => {
        if(data.isAndroid === true) {
            return iconAndroid
        }
        return iconIphone;
    }

    const getTimeStamp = () => {
        if(data.status === 'replied') {
            return moment.utc(data.replyTime).local().format("YYYY-MM-DD HH:mm");
        }
        return data.timeStamp;
    }

    const onClick = () => {
        props.onClick(data);
    }

    return (
        <div className={classes.itemWrapper} onClick={onClick}>
            <img src={getIcon()} />
            <div>
                <Typography className={classes.content}>{data.content}</Typography>
                <Typography className={classes.timeStamp}>{getTimeStamp()}</Typography>
            </div>
        </div>
    )
}