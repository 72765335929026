import {axiosCore} from "./axiosHelper";

const getUserAuthority = function (token) {
    let url = '/auth/admin/users/feature';

    return new Promise((resolve, reject) => {
        axiosCore.request({
            url: url,
            method: 'get',
            headers: {
                'Authorization': 'opendais ' + token,
            }
        }).then(result => {
            resolve(result.data);
        }).catch(e => {
            reject(e);
        })
    });
}

const getFeedbacks = function (token, offset, limit, status, searchContent, sort) {
    let url = '/auth/admin/feedbacks?limit=' + limit + '&offset=' + offset;
    if(status) {
        url += '&status=' + status
    }
    if(searchContent) {
        url += '&searchContent=' + searchContent
    }
    if(sort) {
        url += '&sort=' + sort
    }

    return new Promise((resolve, reject) => {
        axiosCore.request({
            url: url,
            method: 'get',
            headers: {
                'Authorization': 'opendais ' + token,
            }
        }).then(result => {
            resolve(result.data);
        }).catch(e => {
            reject(e);
        })
    });
}

const replyFeedback = function (token, id, content, category, isCC) {
    let url = '/auth/admin/feedbacks/' + id;

    return new Promise((resolve, reject) => {
        axiosCore.request({
            url: url,
            method: 'put',
            headers: {
                'Authorization': 'opendais ' + token,
            },
            data: {
                replyContent: content,
                category: category,
                isCC: isCC,
                action:"replied"
            }
        }).then(result => {
            resolve(result.data);
        }).catch(e => {
            reject(e);
        })
    });
}

export default {
    getUserAuthority,
    getFeedbacks,
    replyFeedback
}