const SupportMap = [
    { value: 'rfq', label: 'RFQ'},
    { value: 'quotation', label: 'Quotation'},
    { value: 'po', label: 'PO'},
    { value: 'account', label: 'Account'},
    { value: 'subscription', label: 'Subscription'},
    { value: 'others', label: 'Others'},
];

const getLabelByValue = (value) => {
    let correctItem = SupportMap.find((item)=> {
        return item.value === value;
    })
    return correctItem? correctItem.label: '';
}

export {
    SupportMap,
    getLabelByValue
}
