import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        height: '92px',
        textAlign: 'center',
        padding: '20px 8px',
        boxSizing: 'border-box',
        borderBottom: '1px solid #cdcdcd'
    },
    selected: {
        background: '#12498e',
        color: '#ffffff'
    },
    normal: {
        background: '#ffffff',
        color: '#838383',
        '&:hover': {
            background: '#f1f5fb',
        }
    },
}));

export default function NavigatorButton(props) {
    const classes = useStyles();

    const getStyle = () => {
        if(props.selected) {
            return `${classes.selected} ${classes.wrapper}`;
        } else {
            return `${classes.normal} ${classes.wrapper}`;
        }
    }

    const onClick = () => {
        props.onClick(props.onClick(props.text));
    }

    return (
        <div className={ getStyle() } onClick={onClick}>
            <img src={props.icon} />
            <Typography variant="body2">{props.text}</Typography>
        </div>
    );
}