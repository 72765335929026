import {useTranslation} from "react-i18next";
import CommonLayout from "../../components/CommonLayout";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, IconButton, InputAdornment, OutlinedInput, Tab, Tabs, TextField} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import FeedbackDetail from "./FeedbackDetail";
import {useEffect, useState} from "react";
import coreService from "../../services/coreService";
import useToken from "../../hooks/useToken";
import Typography from "@material-ui/core/Typography";
import {Pagination} from "@material-ui/lab";
import FeedbackItem from "./FeedbackItem";
import sortIcon from "../../assets/img/sort@2x.png";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px 8px',
    },
    total: {
        color: '#838383',
        margin: '8px 0px 8px 8px !important'
    },
    searchInput: {
        width: 'calc(100% - 50px)'
    },
    orderBtn: {
        verticalAlign: "unset",
        '&>* img': {
            height: '25px',
            width: '25px',
        }
    }
}));

const TabMap = ['new', 'replied', 'anonymous'];
export default function Feedback(props) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const {token} = useToken();
    const [currentTab, setCurrentTab] = useState(0);
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [detail, setDetail] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [searchContent, setSearchContent] = useState(0);
    const [order, setOrder] = useState('desc');
    const PAGE_SIZE = 10;

    useEffect(()=> {
        reloadData();
    }, [])

    const onTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        loadData(0, PAGE_SIZE, newValue, order);
    }

    const loadData = (offset, limit, tab, order) => {
        const sort = getSortBy(tab, order);
        coreService.getFeedbacks(token, offset, limit, TabMap[tab], searchContent, sort).then((data)=> {
            if(data && data.data) {
                let contentData = data.data;
                if(contentData.datas && contentData.datas.length > 0) {
                    setList(formatTimeStamp(contentData.datas));
                    setDetail(contentData.datas[0]);
                    setTotal(contentData.count);
                    setTotalPage(Math.ceil(contentData.count / PAGE_SIZE));
                    return;
                }
            }
            reset();
        }).catch(()=> {
            reset();
        });
    }

    const reset = ()=> {
        setList([]);
        setDetail(null);
        setTotal(0);
        setTotalPage(0);
    }

    const formatTimeStamp = (data)=> {
        return data.map((item)=> {
            let newItem = item;
            newItem.timeStamp = moment.utc(item.createTime).local().format("YYYY-MM-DD HH:mm");
            return newItem;
        });
    }

    const showList = () => {
        return list && list.length > 0;
    }

    const getTotalText = () => {
        return 'About ' + total + ' results'
    }

    const onPageChange = (event, newValue) => {
        setPage(newValue);
        loadData((newValue - 1) * PAGE_SIZE, PAGE_SIZE, currentTab, order);
    }

    const onItemClick = (data) => {
        setDetail(data);
    }

    const reloadData = () => {
        loadData((page - 1) * PAGE_SIZE, PAGE_SIZE, currentTab, order);
    }

    const onSearchMouseDown = (e) => {
        if(e.keyCode === 13) {
            reloadData();
        }
    }

    const onInputSearch = (content) => {
        setSearchContent(content);
    }

    const getSortBy = (tab, order) => {
        return (tab === 1? 'reply_time': 'create_time') + ' ' + order;
    }

    const onSortChange = () => {
        const newOrder = order === 'desc'? 'asc': 'desc';
        setOrder(newOrder);
        loadData((page - 1) * PAGE_SIZE, PAGE_SIZE, currentTab, newOrder);
    }

    return (
        <CommonLayout>
            <Grid container className={classes.container}>
                <Grid item xs={6} md={5}>
                    <div>
                        <OutlinedInput margin="dense" onChange={(e)=>onInputSearch(e.target.value)}
                                       className={classes.searchInput}
                                       onKeyDown={onSearchMouseDown}
                                       startAdornment={
                                           <InputAdornment position="start">
                                               <IconButton
                                                   aria-label="search"
                                                   onClick={reloadData}
                                                   edge="start"
                                               ><SearchIcon />
                                               </IconButton>
                                           </InputAdornment>} />
                        <IconButton
                            aria-label="search"
                            onClick={onSortChange}
                            edge="end"
                            size={'medium'}
                            className={classes.orderBtn}
                        >
                            <img src={sortIcon} />
                        </IconButton>
                    </div>

                    <Tabs
                        value={currentTab}
                        onChange={onTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="UnReplied" />
                        <Tab label="Replied" />
                        <Tab label="Anonymous" />
                    </Tabs>

                    <Typography variant={'body2'} className={classes.total}>{getTotalText()}</Typography>

                    {showList() &&
                    <div>
                        {
                            list.map((item, index)=> {
                                return (<FeedbackItem data={item} onClick={onItemClick} key={index}></FeedbackItem>)
                            })
                        }
                        <Pagination count={totalPage} page={page} onChange={onPageChange} style={{marginTop: '10px'}}></Pagination>
                    </div>
                    }
                </Grid>
                <Grid item xs={6} md={7}>
                    {detail && <FeedbackDetail data={detail} onReplySuccess={reloadData}></FeedbackDetail>}
                </Grid>
            </Grid>
        </CommonLayout>
    )
}