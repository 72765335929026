import axios from 'axios';
import {redirectToLogin} from './loginService';

const requestHandler = request => {
    const tokenString = sessionStorage.getItem('token');
    const token = JSON.parse(tokenString);
    if (token) {
        if (request.headers.Authorization === undefined || request.headers.Authorization === '') {
            request.headers['Authorization'] = "opendias " + token.access_token
        }
    }
    if (request.method.toLowerCase() === 'get') {
        if (!request.headers['Cache-Control']) {
            request.headers['Cache-Control'] = "no-cache";
        }
        if (!request.headers['Pragma']) {
            request.headers['Pragma'] = "no-cache";
        }
    }
    return request;
};

const responseHandler = response => {
    return response;
};

const errorHandler = error => {
    console.error(error);
    if (error.response && error.response.status && error.response.status + "" === '403') {
        if(!error.response.data || !error.response.data.code) {
            window.sessionStorage.clear();
            window.localStorage.setItem('access_url',window.location.href);
            redirectToLogin();
        }
    }
    return Promise.reject(error);
};

const coreInst = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL + '/rfq/'
});
coreInst.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);
coreInst.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export const axiosCore = coreInst;

