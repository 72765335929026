import {useEffect, useState} from "react"
import jwtUtil from '../utils/jwtUtil';
import coreService from '../services/coreService';
import useToken from '../hooks/useToken';

export default function IAMCallback() {
  const _hash = window.location.hash;
  const [hash] = useState(_hash);

  const {setToken} = useToken();
  useEffect(() => {
    const accessToken = jwtUtil.encode(hash);
    console.log(accessToken);
    setToken(accessToken);
    coreService.getUserAuthority(accessToken).then((data) => {
      if(data) {
        if (window.localStorage.getItem('access_url')) {
          const redirectUrl = window.localStorage.getItem('access_url');
          window.localStorage.removeItem('access_url');
          window.location.href = redirectUrl;
        } else {
          window.location.href = "/";
        }
      }

    }).catch((error) => {
      console.error(error);
      if(error.response && error.response.data && error.response.data.code == '403') {
        setToken(accessToken);
        window.location.href = '/unauthorized';
      }
    })
  });

  return (
    <div>
    </div>
  )
}
