import {Route, Switch} from "react-router-dom";
import React from "react";
import Feedback from "./pages/feedback/Feedback";
import UnAuthorized from "./pages/UnAuthorized";

export default function AppRouters(props) {
    return (
        <Switch>
            <Route path='/feedback' exact>
                <Feedback/>
            </Route>
            <Route path='/unauthorized' exact>
                <UnAuthorized />
            </Route>
            <Route path='/'>
                <Feedback/>
            </Route>
        </Switch>
    )

}
